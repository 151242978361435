/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { useSurvey } from '../../context/SurveyProvider';
import listenOnApple from '../../images/listen-on-apple.png';
import listenOnSpotify from '../../images/listen-on-spotify.png';
import listenNow from '../../images/listen-now.png';

export const Buttons = () => {
  const { surveyData } = useSurvey();
  return (
    <div className="bottom__play-container">
      {(surveyData.episodeImage || surveyData.podcastImage) && (
        <img className="play" src={surveyData.episodeImage || surveyData.podcastImage} alt="Play" />
      )}
      <div className="play-links">
        {surveyData.appleEpisodeLink && (
          <a
            href={surveyData.appleEpisodeLink}
            id="apple-follow"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="apple" src={listenOnApple} alt="Listen on Apple" />
          </a>
        )}
        {surveyData.spotifyEpisodeLink && (
          <a
            href={surveyData.spotifyEpisodeLink}
            id="spotify-follow"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="spotify" src={listenOnSpotify} alt="Listen on Spotify" />
          </a>
        )}
      </div>
      <img className="listen-now" src={listenNow} alt="Listen Now" />
    </div>
  );
};
